<template>
  <div class="card-ground">
    <div class="round-background">
    </div>
    <ion-card>
      <ion-card-header>
        <span class="dot"></span>
        <ion-card-title>{{ firnam }} {{ lasnam }}</ion-card-title>
        <ion-card-subtitle>{{ jobdes || "no description" }}</ion-card-subtitle>
        <ion-card-subtitle v-if="days != null">Last visit: <strong>{{ days }}</strong> days ago</ion-card-subtitle>
        <ion-card-subtitle v-else>no visit</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <ion-button class="icon-buttons" fill="outline" @click="openGoogleMap(lat, lng)">
          <ion-icon slot="icon-only" :icon="location"></ion-icon>
        </ion-button>
        <router-link
            :to="{
          name: 'AppointmentFormPage',
          params: {
            compName: this.bpcnam,
            plz: this.poscod,
            emailSub : this.email,
            loc: this.add,
            city: this.city,
            name: this.lasnam,
            firstname: this.firnam
          }}">
            <ion-button @click="loadForm" class="icon-buttons" fill="outline">
          <ion-icon slot="icon-only" :icon="calendar">
          </ion-icon>
        </ion-button>
        </router-link>
        <ion-button class="icon-buttons" fill="outline" @click="sendmail(email)">
          <ion-icon slot="icon-only" :icon="mail"></ion-icon>
        </ion-button>
        <ion-button class="icon-buttons" fill="outline" @click="dial(tel)">
          <ion-icon slot="icon-only" :icon="call" type="mail"></ion-icon>
        </ion-button>
      </ion-card-content>

    </ion-card>
  </div>
</template>

<script>

import {calendar, call, location, mail, menu} from 'ionicons/icons';
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,} from "@ionic/vue";

export default {
  name: "ClientOverview",
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    IonButton,
  },
  props: [
    'firnam',
    'poscod',
    'lat',
    'bpcnam',
    'lng',
    'lasnam',
    'jobdes',
    'tel',
    'days',
    'email',
    'add',
    'city'
  ],
  data() {
    return {
      location,
      calendar,
      mail,
      call,
      menu,
    };
  },
  methods: {
    loadForm(){
      try {
        this.$router.push(
            {
              name: 'AppointmentFormPage',
              props: [
                this.bpcnam,
                this.poscod,
                this.email,
                this.add,
                this.city,
                this.firnam,
                this.lasnam,
              ],
            }).catch(error => {
              console.log(error)
              this.$router.push("/login");
              // alert('Please log in again!')
        })
      } catch (errorMessage){
        console.log(errorMessage);
      }
    },
    dial: function (number) {
      window.location = 'tel:' + number;
    },
    sendmail: function (address) {
      window.location = 'mailto:' + address;
    },
    openGoogleMap(lat, lng) {
      window.open(
          `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
          "_blank"
      );
    }
  },
}
</script>

<style scoped>

.menu-icon {
  font-size: 50px;
  margin-left: 0.5rem;
}

.round-background {
  height: 30rem;
  width: 30rem;
  background-color: #c06f62;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-ground {
  background-color: var(--ion-color-primary);
  width: 100%;
  height: 100%;
  position: relative;
}

h1 {
  height: 1.5rem;
  padding-left: 2.5rem;
  align-items: center;
  display: flex;
  color: black;
}

.dot {
  height: 8rem;
  width: 8rem;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  display: inline-block;
}

.icon-buttons {
  --border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  margin: 2.5%;
  color: var(--ion-color-primary);
  --border-color: #b30000;
}

ion-card {
  margin-inline: 0;
  padding-top: 5rem;
  width: 20rem;
  height: 30rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-icon {
  font-size: 35px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

ion-card-title {
  font-weight: bolder;
}


</style>