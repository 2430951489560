<template>
  <ion-page>
    <ion-toolbar>
      <div class="col-12 text-left">
      <router-link to="/home">
        <ion-icon @click="resetForm" class="home-icon" slot="icon-only" :icon="home"></ion-icon>
      </router-link>
      </div>
    </ion-toolbar>
    <ion-content>
      <div class="container appointment-container">
        <div class="row justify-content-md-center">
          <div class="col-sm-4 text-left">
            <form>
              <div class="form-group">
                <!--https://getbootstrap.com/docs/4.0/components/forms/-->
                <ion-label for="titleInput">Title</ion-label>
                <input type="text" class="form-control" id="titleInput" placeholder="Meeting" v-model="title">

                <ion-label>Date</ion-label>
                <DatepickerTime class="start-date" v-model="StartDate" placeholder="Start Date"/>
                <DatepickerTime class="end-date" v-model="EndDate" :placeholder="EndDate"/>

                <ion-label for="locationInput">Location</ion-label>
                <input type="text" class="form-control" id="locationInput" placeholder="Masstrasse 2, Stadt"  v-model="changedLoc">

                <ion-label for="subsInput">Attendees</ion-label>
                <input type="text" class="form-control" id="subsInput" :placeholder="placeholderSub" v-model="changedSub" :disabled="!checkedInv">
              </div>
            </form>
          </div>
          <div class="col-sm-8 text-left padding-setting">
            <div class="container-fluid">
              <div class="row justify-content-left">
                <div class="col-sm-12 text-left">
                  <div class="form-group">
                    <ion-label for="bodyArea">Body</ion-label>
                    <textarea class="form-control" id="bodyArea" rows="9" v-model="body"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="row justify-content-left">
                <div class="col-sm-12 text-left">
                  <ion-label class="invitation-label">Invitation</ion-label>
                  <ion-toggle type="checkbox" id="toggle-all-day" v-model="checkedInv" />
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="row justify-content-left">
                <div class="col-sm-6 text-left">
                  <ion-label class="teams-label">Teams</ion-label>
                  <ion-toggle type="checkbox" id="toggle-teams" v-model="checkedTeams" />
                </div>
                <div class="col-sm-6 text-right">
                  <ion-button @click="createAppointment">Confirm</ion-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import {IonIcon, IonPage, IonContent, IonLabel, IonButton, IonToggle, IonToolbar} from "@ionic/vue";

import DatepickerTime from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from "axios";
import {help, home, map, menu} from "ionicons/icons";

export default {
  name: "AppointmentFormPage",
  components: {
    IonPage,
    IonToolbar,
    IonContent,
    IonIcon,
    DatepickerTime,
    IonLabel,
    IonButton,
    IonToggle
  },
  data() {
    return {
      companyName: null,
      placeholderSub: 'x.infon@email.com, x2.info@email.com',
      response: null,
      changedLoc: null,
      bearerToken: this.$store.getters.getToken,
      appointmentId: null,
      StartDate: null,
      EndDate: null,
      title: null,
      sub: this.emailSub,
      menu,
      map,
      help,
      home,
      checkedPrivate: null,
      checkedInv: null,
      checkedTeams: null,
      body: null,
      location: null,
      citySub: null,
      clientName: null,
      clientFirstname: null,
      changedSub: null,
    };
  },
  props: [
      'emailSub',
      'compName',
      'loc',
      'plz',
      'city',
      'name',
      'firstname',
  ],
  watch:{
    // is a watcher for the placeholder if the user choose the startdate
    StartDate(){

      // helper value
      let chosedDate;

      if (this.StartDate != null){
        chosedDate = new Date(this.StartDate)

        // add one hour to the end date from start date
        this.EndDate = new Date(chosedDate.setTime(chosedDate.getTime() + 1 * 60 * 60 * 1000));
      }
    },
  },
  methods: {
    // a function for resetting the form after submitting
    resetForm(){
      this.title = null;
      this.StartDate = null;
      this.EndDate = null;
      this.body = null;
      this.checkedTeams = false;
      this.checkedInv = false;

      this.$router.push("/home");
    },
    // get the appointment-ID for the next step
    async getAppointmentId() {

      let gotTokenUrl = 'https://graph.microsoft.com/v1.0/me/calendar'

      let config = {
        headers: {
          'Authorization': 'Bearer ' + this.bearerToken,
          'Content-Type': 'application/json'
        }
      }
      try {
        await axios
            .get(gotTokenUrl, config)
            .then(res => {
              this.appointmentId = res.data.id
            })
      } catch (err){
        console.log(err)
      }

    },
    async createAppointment(){

      // array for attendees
      let attendees = [];

      // checking if the user changed the address of the appointment
      if (this.changedLoc != null){
        this.location = this.changedLoc;
      }

      if (this.changedSub != null){
        this.sub = this.changedSub;
      }

      // checking if the user wants to send an invitation to the client
      if (!this.checkedInv){
        attendees.push(
            {
              "emailAddress": {
                "address": null,
                "name": null,
              },
              "type": "required"
            },
        );
      } else {

        // checking if there are more attendees than one
        if (this.sub.includes(',')) {
          var attes_array = this.sub.split(',');

        } else {
          attes_array = this.sub.split(' ');
        }

        for (let item of attes_array) {
          attendees.push(
              {
                "emailAddress": {
                  "address": item.replace(/\s/g, ''),
                  "name": null,
                },
                "type": "required"
              },
          );
        }
      }



      try {
        await axios({
          method: 'post',
          url: 'https://graph.microsoft.com/v1.0/me/calendars/' + this.appointmentId + '/events',
          data: {
            "subject": this.title,
            "body": {
              "contentType": "HTML",
              "content": this.body
            },
            "start": {
              // add two hours to StartDate and replace the Z on the end
              "dateTime": new Date(this.StartDate.setHours(this.StartDate.getHours() + 2)).toISOString().replace('Z', ''),
              // "dateTime": new Date(this.StartDate.setHours(this.StartDate.getHours() + 2)).toISOString().replace('Z', ''),
              "timeZone": "W. Europe Standard Time"
            },
            "end": {
              // add two hours to EndDate and replace the Z on the end
              "dateTime": new Date(this.EndDate.setHours(this.EndDate.getHours() + 2)).toISOString().replace('Z', ''),
              "timeZone": "W. Europe Standard Time"
            },
            "location": {
              "displayName": this.location
            },
            "attendees": attendees,
            "isOnlineMeeting": this.checkedTeams,
            "onlineMeetingProvider": "teamsForBusiness"
          },
          headers: {
            'Authorization': 'Bearer ' + this.bearerToken,
            'Content-Type': 'application/json'
          }
        })
            .then(res => this.status = res.status)
        this.resetForm();





      } catch (e){
        let errorMessage = e.toString();
        if (errorMessage.includes("Hours")){
          alert("Please check the Start and End date!");
        } else if (errorMessage.includes("401")) {
          // redirect to the LoginPage and the user has to log in again
          await this.$router.push("/login");
          // alert("Please log in again");
        }
      }
    },
  },
  async created() {
    try {
      // passing props of the previous view (ClientPage/ClientOverview)
      await this.$watch(() => this.$route.params,(toParams) => {
        if (toParams != undefined){
          this.companyName = toParams.compName;
          this.sub = toParams.emailSub;
          this.location = toParams.loc + ", " + toParams.plz + ", " + toParams.city;
          this.changedLoc = this.location;
          this.changedSub = this.sub;
          this.clientFirstname = toParams.firstname;
          this.clientName = toParams.name;
          this.title = "Meeting, " + this.companyName + ", " + this.clientFirstname + " " + this.clientName
        }
        else  {
          console.log("toParams is empty");
        }
      })
      await this.getAppointmentId();
    } catch (e) {
        console.log(e)
    }
  }
}
</script>

<style scoped>

.invitation-label{
  margin-right: 3rem!important;
}

.teams-label{
  margin-right: 4.4rem!important;
}


ion-content {
  --ion-background-color: var(--ion-color-primary);
}

.appointment-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: #c06f62;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 6px;
}

.form-control {
  margin-bottom: 1rem;
}

.start-date {
  margin-bottom: 1rem;
}

.end-date {
  margin-bottom: 1rem;
}

ion-datetime {
  border-radius: 16px;
  border: 1px black;
}

ion-label {
  font-weight: bold;
  padding-leftin: 5rem;
}

ion-toggle {
  --background: var(--ion-color-medium-shade);
  --background-checked: var(--ion-color-primary);

  --handle-background: white;
  --handle-background-checked: white;
  height: 1.5rem;
  width: 2.5rem;
  padding: 0.5rem 0px 0px 2rem;
}

ion-button {
  height: 1.5rem;
}

.padding-setting {
  padding-left: 0px;
  padding-right: 0px;
}

.home-icon {
  font-size: 35px;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  color: var(--ion-color-primary);
}

.help-icon {
  font-size: 35px;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.help-icon:hover {
  cursor: pointer;
}


</style>