<template>
  <ion-page>
<!--    <ion-header>-->
      <ion-toolbar>
        <ion-buttons>
          <ion-back-button class="go-back" defaultHref="/home" slot="start"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
<!--    </ion-header>-->
    <ion-content>
      <h1 v-if="!loadedAppointments">Bitte wieder zur Startseite und warten bis die Kunden-Daten neugeladen sind.</h1>
      <appointment-overview v-else
                            :id="loadedAppointments.id"
                            :subject="loadedAppointments.subject"
                            :body="loadedAppointments.body.content"
                            :start="loadedAppointments.start.dateTime"
                            :url="loadedAppointments.onlineMeeting"
                            :outlookWeb="loadedAppointments.webLink"
                            :end="loadedAppointments.end.dateTime"
                            :loc="loadedAppointments.location.displayName">
      </appointment-overview>
    </ion-content>
  </ion-page>
</template>

<script>

import AppointmentOverview from "../components/appointments/AppointmentOverview";

import {IonBackButton, IonButtons, IonContent, IonPage, IonToolbar} from "@ionic/vue";

export default {
  name: "AppointmentPage",
  components: {
    IonPage,
    AppointmentOverview,
    IonContent,
    IonBackButton,
    IonToolbar,
    IonButtons,
  },
  data() {
    return {
      appointmentId: this.$route.params.id,
    };
  },
  computed: {
    loadedAppointments() {
      return this.$store.getters.loadedStoreAppointments(this.appointmentId);
    },
  },
  created() {
    this.$store.commit('addAppointments', JSON.parse(localStorage.getItem('appointments')));
  }
}
</script>

<style scoped>

h1 {
  margin-left: 0.5rem;
}

ion-toolbar {
  background-color: #ffffff;
}

.go-back {
  color: var(--ion-color-primary);
}

</style>