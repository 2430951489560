<template>
  <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-buttons>
            <ion-back-button class="go-back" defaultHref="/home" slot="start"></ion-back-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <h1 v-if="!loadedClients">Bitte wieder zur Startseite und warten bis die Kunden-Daten neugeladen sind.</h1>

        <client-overview v-else
                         :bpcnam="loadedCompany.bpcnam"
                         :firnam="loadedClients.firnam"
                         :poscod="loadedCompany.poscod"
                         :email="loadedClients.mail"
                         :lasnam="loadedClients.lasnam"
                         :tel="loadedClients.tel"
                         :jobdes="loadedClients.jobdes"
                         :days="loadedCompany.days"
                         :lat="loadedCompany.lat"
                         :lng="loadedCompany.lng"
                         :add="loadedCompany.bpaadd"
                         :city="loadedCompany.cty"
        >
        </client-overview>
      </ion-content>
  </ion-page>
</template>

<script>

import ClientOverview from "../components/clients/ClientOverview";

import {IonBackButton, IonButtons, IonContent, IonToolbar, IonHeader, IonPage} from "@ionic/vue";

export default {
  name: "ClientPage",
  components: {
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    IonBackButton,
    IonButtons,
    ClientOverview
  },
  data() {
    return {
      clientId: this.$route.params.clinr,
    };
  },
  computed: {
    loadedCompany() {
      return this.$store.getters.client(this.clientId)
    },
    loadedClients() {
      return this.$store.getters.client(this.clientId).empl.find(x => x.clinr === this.clientId);
    },
  },
  created() {
    this.$store.commit('addClients', JSON.parse(localStorage.getItem('clients')));
  }
}
</script>

<style scoped>

h1 {
  margin-left: 0.5rem;
}

ion-toolbar {
  background-color: var(--ion-toolbar-background);
}

.go-back {
  color: var(--ion-color-primary);
}

</style>