import {
    createRouter,
    createWebHistory
} from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import ClientPage from "../views/ClientPage";
import LoginPage from "../views/LoginPage";
import VisitMap from "../views/VisitMap";
import AppointmentPage from "../views/AppointmentPage";
import AppointmentFormPage from "../views/AppointmentFormPage";
import store from "../store/index.js";
import jwt_decode from "jwt-decode";
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/home',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/map',
        name: 'VisitMap',
        component: VisitMap
    },
    {
        path: '/create-appointment',
        name: 'AppointmentFormPage',
        component: AppointmentFormPage,
        props: true,
        // checking if the accessToken is valid, if not redirect to the LoginPage
        beforeEnter: (to, from, next) => {

            try {
                // get the Token from vuex-store
                let accessToken = store.getters.getToken;
                // decode the JWT-Token to the expiry value (example: "exp": 1665410910)
                let expToken = jwt_decode(accessToken)['exp'];
                let dateNow = Date.now() / 1000;
                if (expToken < dateNow){
                    // redirect to the login-page
                    next({ name: 'Login'});
                } else {
                    // routes to the AppointmentFormPage
                    next();
                }
            } catch (e) {
                console.log(e)
                next({ name: 'Login'});
                alert('Please log in again!')
            }
        }
    },
    {
        path: '/appointment/:id',
        name: 'Appointment',
        component: AppointmentPage
    },
    {
        path: '/client/:clinr',
        name: 'Client',
        component: ClientPage
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


export default router