<template>
  <ion-page>
    <div class="log-bar">
      <ion-img :src="image"/>
      <h1>Aussendienst App</h1>
    </div>
    <div class="forms">
      <button @click="SignIn">Login</button>
    </div>
  </ion-page>
</template>

<script>
import {IonImg, IonPage} from "@ionic/vue";

import {computed} from 'vue';
import {PublicClientApplication} from '@azure/msal-browser'
import router from "../router";

export default {
  name: 'LoginPage',
  components: {
    IonPage,
    IonImg
  },
  data() {
    return {
      accessTokenRequest: undefined,
      account: undefined,
      myMSAL: undefined,
      para: this.$store.state.msalAuth,

      // loginRequest variable
      loginRequest: this.$store.state.msalRequest,

      // msalConfig variable
      config: this.$store.state.msalConfig,

    };
  },
  setup() {
    const image = computed(() => require('../../public/assets/logo.svg'))

    return {
      image,
    }
  },
  async created() {
    this.myMSAL = new PublicClientApplication(
        this.config
    );
  },

  methods: {
    async SignIn() {
      this.myMSAL = new PublicClientApplication(
          this.config
      );
      this.myMSAL
          .loginPopup(this.loginRequest)
          .then(async () => {
            const myAccounts = this.myMSAL.getAllAccounts();
            this.account = myAccounts[0];
            this.$emitter.emit('login', this.account);
            this.$store.commit("getBool", true);
            if (this.$store.getters.getBool !== false) {
              await router.push('/home');
            }
          }).catch(error => {
        console.log(error);
      })

    },

  },
};


</script>

<style scoped>

.log-bar {
  background-color: var(--ion-color-primary);
  text-align: center;
  color: white;
}

.forms {
  margin: 3rem auto 40rem;
  max-width: 15rem;
}

button {
  background-color: var(--ion-color-primary);
  width: 15rem;
  height: 3rem;
  border-radius: 5rem;
  color: white;
}

ion-img {
  max-width: 5rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
}

</style>