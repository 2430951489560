<template>
  <ion-list>
    <client-list-item
        v-for="client in showClients"
        :key="client.bpcnr"
        :listClient="client">
    </client-list-item>
  </ion-list>
</template>

<script>

import {
  IonList
} from '@ionic/vue';
import ClientListItem from "./ClientListItem";

export default {
  name: "ClientList",
  components: {
    ClientListItem,
    IonList
  },
  props: [
    "showClients",
  ]
}
</script>

<style scoped>

ion-list {
  cursor: pointer;
}

</style>