<template>
  <ion-page>
    <ion-header>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-6 text-left test">
            <router-link to="/login">
              <ion-icon @click="SignOut" class="menu-icon" slot="icon-only" :icon="logOut"></ion-icon>
            </router-link>
          </div>
          <div class="col-6 text-right">
            <ion-icon @click="openPDF" class="help-icon" slot="icon-only" :icon="help">
            </ion-icon>
            <router-link to="/map">
              <ion-icon class="map-icon" slot="icon-only" :icon="map"></ion-icon>
            </router-link>
          </div>
        </div>
      </div>
    </ion-header>
    <div class="appointment-bar">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-2 text-left test">
            <ion-icon v-if="currentSlide > 0" @click="prev" class="back-chevron-icon" slot="icon-only" :icon="chevronBackOutline"></ion-icon>
          </div>
          <div class="col-8 text-center">
<!--            <h3 v-if="currentSlide == 0">Termine, heute {{ todaysDate() }}</h3>-->
<!--            <h3 v-else-if="currentSlide == 1">Termine, morgen {{ tomorrowsDate() }}</h3>-->
<!--            <h3 v-else>Termine, übermorgen {{ threeDaysDate() }}</h3>-->
            <h3 v-if="currentSlide == 0">Termine, heute</h3>
            <h3 v-else-if="currentSlide == 1">Termine, morgen</h3>
            <h3 v-else>Termine, übermorgen</h3>
          </div>
          <div class="col-2 text-right">
            <ion-icon v-if="currentSlide <= 1" @click="next" class="menu-icon" slot="icon-only" :icon="chevronForwardOutline"></ion-icon>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
    <ion-content>
      <appointment-list v-if="currentSlide == 0"  :appointments="loadedTodayAppointments"></appointment-list>
      <appointment-list v-else-if="currentSlide == 1" :appointments="loadedTomorrowAppointments"></appointment-list>
      <appointment-list v-else :appointments="loadedThreeDaysAppointments"></appointment-list>
    </ion-content>
    <div class="bar-clients">
      <h3>Kunden</h3>
      <input
          v-model="search"
          class="search-box"
          placeholder="search company"
          type="text"
          v-on:keyup.enter="filterList"
      />
      <button
          class="confirm-search"
          @click="filterList"
      >Search
      </button>
    </div>
    <ion-content>
      <div v-if="!isLoaded" id="loading" class="loading">

      </div>
      <client-list :showClients="filterResults"></client-list>
    </ion-content>
  </ion-page>
</template>


<script>

import {IonContent, IonHeader, IonIcon, IonPage} from "@ionic/vue";
import {logOut, map, menu, help, chevronForwardOutline, chevronBackOutline} from "ionicons/icons";
import ClientList from "@/components/clients/ClientList";
import AppointmentList from "@/components/appointments/AppointmentList";
import axios from "axios";
import {PublicClientApplication} from "@azure/msal-browser";
import jwt_decode from "jwt-decode";

export default {
  name: "HomePage",
  components: {
    AppointmentList,
    IonHeader,
    ClientList,
    IonContent,
    IonPage,
    IonIcon,
  },
  data() {
    return {
      currentSlide: 0,
      isLoaded: false,
      search: '',
      menu,
      help,
      map,
      chevronForwardOutline,
      chevronBackOutline,
      logOut,
      myMSAL: undefined,
      config: this.$store.state.msalConfig,
      account: undefined,
      loadedAppointments: null,
      loadedTodayAppointments: null,
      loadedTomorrowAppointments: null,
      loadedThreeDaysAppointments: null,
      logoutRequest: this.$store.state.msalRequest,
      accessTokenRequest: undefined,
      accessToken: null,
      clients: null,
      loggedUser: '',

    };
  },
  watch:{
    // is a watcher for the placeholder if the user choose the startdate
    currentSlide(){
      if (this.currentSlide === 0){
        this.$store.commit('addAppointments', this.loadedTodayAppointments);
        localStorage.setItem('appointments', JSON.stringify(this.loadedTodayAppointments));
      } else if (this.currentSlide === 1){
        this.$store.commit('addAppointments', this.loadedTomorrowAppointments);
        localStorage.setItem('appointments', JSON.stringify(this.loadedTomorrowAppointments));
      } else if (this.currentSlide === 2){
        this.$store.commit('addAppointments', this.loadedThreeDaysAppointments);
        localStorage.setItem('appointments', JSON.stringify(this.loadedThreeDaysAppointments));
      }
    },
  },
  methods: {
    next(){
      this.currentSlide++
    },
    prev(){
      --this.currentSlide
    },
    todaysDate(){
      let today = new Date();

      return today.toLocaleDateString();

    },
    tomorrowsDate(){
      let today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      return tomorrow.toLocaleDateString();

    },
    threeDaysDate(){
      let today = new Date();
      let threeDays = new Date(today);
      threeDays.setDate(threeDays.getDate() + 2);

      return threeDays.toLocaleDateString();

    },
    // Function for opening help source
    openPDF(){
      window.open("./assets/help.pdf");
    },
    // Function for filtering the list of clients (ignoring uppercase and lowercase)
    filterList() {
      this.clients = this.$store.getters.clients.filter(x => x.bpcnam.toLowerCase().match(this.search.toLowerCase()))
    },
    // Function for acquiring the access-token from MSAL
    async access() {
      this.myMSAL = new PublicClientApplication(
          this.config
      );
      const myAccounts = this.myMSAL.getAllAccounts();
      const account = myAccounts[0];

      this.accessTokenRequest = {
        scopes: [
          "user.read",
          "calendars.read"
        ],
        account: account,
      }

      await this.myMSAL
          .acquireTokenSilent(this.accessTokenRequest)
          .then(accessTokenResponse => {
            // Acquire token silent success
            this.$store.commit('setAccessToken', accessTokenResponse.accessToken);

            // decode the access-token for the mail of the logged user
            let firstName = jwt_decode(this.$store.getters.getToken)['given_name'];
            let name = jwt_decode(this.$store.getters.getToken)['family_name'];
            this.loggedUser = firstName + '.' + name;
          })
    },
    // function for loading the appointments from logged user
    async todayAppointmentLoader() {
      // setDate for calendar events
      const today = new Date()
      // setDate from the same date till midnight 23:59:59
      let midnight = new Date(today.getFullYear()
          , today.getMonth()
          , today.getDate()
          , 23, 59, 59);


      this.today = today.toLocaleTimeString();

      let graphEndpoint = 'https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=' + today.toISOString() + '&enddatetime=' + midnight.toISOString() +'&$orderby=start/dateTime';

      let axiosConfig ={
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.getToken,
              // timezone in header for the calendar events
              'Prefer': 'outlook.timezone="Europe/Berlin"'
        }
      }

      await axios
          .get(graphEndpoint, axiosConfig)
          .then(res => {
            this.loadedTodayAppointments = res.data.value;
            this.$store.commit('addTodayAppointments', res.data.value);
            localStorage.setItem('todayApps', JSON.stringify(res.data.value));

            this.$store.commit('addAppointments', res.data.value);
            localStorage.setItem('appointments', JSON.stringify(res.data.value));
          })
    },
    // function for loading the appointments from logged user
    async tomorrowAppointmentLoader() {
      // setDate for calendar events
      const today = new Date()
      // setDate from the same date till midnight 23:59:59
      let midnight = new Date(today.getFullYear()
          , today.getMonth()
          , today.getDate() + 1
          , 23, 59, 59);

      this.today = today.toLocaleTimeString();

      // set the date to tomorrow at midnight
      let tomorrow = new Date(today.getFullYear()
          , today.getMonth()
          , today.getDate() + 1
          , 0, 0, 1);


      let graphEndpoint = 'https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=' + tomorrow.toISOString() + '&enddatetime=' + midnight.toISOString() +'&$orderby=start/dateTime';

      let axiosConfig ={
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.getToken,
          // timezone in header for the calendar events
          'Prefer': 'outlook.timezone="Europe/Berlin"'
        }
      }

      await axios
          .get(graphEndpoint, axiosConfig)
          .then(res => {
            this.loadedTomorrowAppointments = res.data.value;
            this.$store.commit('addTomorrowAppointments', res.data.value);

          })
    },

    // function for loading the appointments from logged user
    async threeDaysAppointmentLoader() {
      // setDate for calendar events
      const today = new Date()
      // setDate from the same date till midnight 23:59:59
      let midnight = new Date(today.getFullYear()
          , today.getMonth()
          , today.getDate() + 2
          , 23, 59, 59);


      this.today = today.toLocaleTimeString();

      // set the date to tomorrow at midnight
      let threeDays = new Date(today.getFullYear()
          , today.getMonth()
          , today.getDate() + 2
          , 0, 0, 1);



      let graphEndpoint = 'https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=' + threeDays.toISOString() + '&enddatetime=' + midnight.toISOString() +'&$orderby=start/dateTime';

      let axiosConfig ={
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.getToken,
          // timezone in header for the calendar events
          'Prefer': 'outlook.timezone="Europe/Berlin"'
        }
      }

      await axios
          .get(graphEndpoint, axiosConfig)
          .then(res => {
            this.loadedThreeDaysAppointments = res.data.value;
            this.$store.commit('addThreeDaysAppointments', res.data.value);

            // this.$store.commit('addAppointments', res.data.value);
            // localStorage.setItem('appointments', JSON.stringify(res.data.value));
          })
    },




    // function for logging out
    async SignOut() {
      this.myMSAL = new PublicClientApplication(
          this.config
      );
      const logoutRequest = {
        account: this.myMSAL.getAllAccounts(),
        mainWindowRedirectUri: "https://ad-app.3s-solar.ch/login"
      }
      await this.myMSAL.logoutPopup(logoutRequest);
    },
    // function for loading the sage data from an API
    async SageAPILoader() {
      await axios({
        method: 'get',
        url: 'https://sageexternapi.3s-solar.swiss/Sage/Customer/GetAllCustomersWithDetails',
      })
          .then(res => {
            const custs = res.data;

            // Filtering response with an AD-mail (vertmail)

            // only for developing, hardcoded AD-name
            // let tryName = "Ivan.Tujkic";
            // let email = tryName.toLowerCase() + "@3s-solar.swiss";

            // only for publishing, dynamic AD-name from logged user
            let email = this.loggedUser.toLowerCase() + "@3s-solar.swiss";

            this.clients = custs.filter(x => x['vertmail'] === email);
            let removedDuplicates = Array.from(new Set(this.clients.map(a => a.bpcnr)))
                .map(bpcnr => {
                  return this.clients.find(a => a.bpcnr === bpcnr)
                })
            this.clients = removedDuplicates;
            localStorage.setItem('clients', JSON.stringify(this.clients));
            this.$store.commit('addClients', this.clients);
            // console.log(this.clients)
          }).catch(error => {
            console.log(error);
          });
    }
  },
  computed: {
    filterResults() {
      if (this.clients !== null) {
        return this.clients;
      } else {
        return [];
      }
    },
  },
  async created() {
    await this.access();
    await this.todayAppointmentLoader();
    await this.tomorrowAppointmentLoader();
    await this.threeDaysAppointmentLoader();
    await this.SageAPILoader();
    this.isLoaded = true;
  }

}
</script>

<style scoped>


#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}


#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

h3 {
  color: white;
}

ul {
  list-style: none;
}

.appointment-bar {
  height: 3.5rem;
  background-color: var(--ion-color-primary);
  /*padding-left: 0!important;*/
  color: white;
  margin-bottom: 0.2rem;
  /*padding-right: 1rem;*/
}

/*important because of the padding-left of "col-2 text-left"*/
.test {
  padding-left: 0!important;
}

.bar {
  height: 3.5rem;
  background-color: var(--ion-color-primary);
  padding-left: 1.1rem;
  color: white;
  margin-bottom: 0.2rem;
  padding-right: 1rem;
}

.next-button-appointments{
  max-width: 15rem;
  height: 2.3rem;
  margin-left: -0.5rem;
  /*margin-right: 1.5rem;*/
  color: black;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 0.5rem;
}

.prev-button-appointments{
  max-width: 15rem;
  height: 2.3rem;
  /*margin-left: 0.5rem;*/
  margin-right: 0.5rem;
  color: black;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 0.5rem;
}

.bar-clients {
  height: 3.5rem;
  background-color: var(--ion-color-primary);
  padding-left: 1.1rem;
  color: white;
  margin-bottom: 0.2rem;
  display: flex;
}

.search-box {
  background-color: #ffffff;
  width: 8rem;
  height: 2.3rem;
  margin-left: auto;
  margin-right: 0.5rem;
  color: black;
  border-radius: 10px;
  border: 1px;
  padding-left: 0.4rem;
  margin-top: 0.5rem;
}

.menu-icon {
  font-size: 35px;
  margin-top: 0.5rem;
  margin-left: 0.7rem;
  cursor: pointer;
}

.back-chevron-icon {
  font-size: 35px;
  margin-top: 0.5rem;
  margin-left: 0rem;
  cursor: pointer;
}

.map-icon {
  font-size: 35px;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  color: var(--ion-color-primary);
}

.help-icon {
  font-size: 35px;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.help-icon:hover {
  cursor: pointer;
}

.confirm-search {
  max-width: 15rem;
  height: 2.3rem;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  color: black;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 0.5rem;
}

/*
spinner css:
  https://projects.lukehaas.me/css-loaders/
*/
.loading{
  color: #000000;
  font-size: 1rem;
  margin: 100px auto;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}




</style>
