<template>
  <button>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton"
}
</script>

<style scoped>

button{
  background-color: var(--ion-color-primary);
  min-width: 5rem;
  max-width: 8rem;
  height: 2rem;
  border-radius: 12px;
  color: white;
}

</style>