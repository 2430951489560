<template>
  <ion-accordion-group>
    <ion-accordion value="clients">
      <ion-item slot="header">
        <!-- name of the company -->
        <ion-label>
          <strong>
            {{ listClient.bpcnam }}
          </strong>
        </ion-label>
      </ion-item>
       list of all clients from the company
      <ion-list slot="content">
        <ion-item
            v-for="employee in listClient.empl"
            :router-link="`/client/${employee.clinr}`"
            :key="employee.clinr">
        {{ employee.firnam }} {{ employee.lasnam }}
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script>

import {
  IonItem,
  IonAccordionGroup,
  IonAccordion,
  IonList,
  IonLabel,
} from '@ionic/vue';

export default {
  name: "ClientListItem",
  components: {
    IonItem,
    IonAccordionGroup,
    IonAccordion,
    IonList,
    IonLabel,
  },
  props: [
    "listClient",
  ],
}
</script>

<style scoped>

</style>