<template>
  <ion-list>
    <appointment-list-item
        v-for="appointment in appointments"
        :key="appointment.id"
        :appointment="appointment">
    </appointment-list-item>
  </ion-list>
</template>

<script>

import {IonList} from '@ionic/vue';
import AppointmentListItem from "./AppointmentListItem";

export default {
  name: "AppointmentList",
  components: {
    AppointmentListItem,
    IonList
  },
  props: [
    "appointments"
  ]
}
</script>

<style scoped>

ion-list {
  cursor: pointer;
}

</style>