import {createStore} from 'vuex';

const store = createStore({
    state() {
        return {
            clients: null,
            msalConfig: {
                auth: {
                    clientId: '31022e90-0ead-4a35-a87a-97796ac0bfe8',

                    // the first redirectUri is for production
                    // redirectUri: "https://ad-app.3s-solar.ch/login", //defaults to application start page,
                    // postLogoutRedirectUri: "https://ad-app.3s-solar.ch/login",

                    // this redirectUri is for our test server
                    redirectUri: "https://ar-customerportal.ch/login", //defaults to application start page,
                    postLogoutRedirectUri: "https://ar-customerportal.ch/login",

                    // the last redirectUri is for dev
                    // redirectUri: "http://localhost:8080/login",
                    // postLogoutRedirectUri: "http://localhost:8080/login",
                    authority:
                        'https://login.microsoftonline.com/f5e0c226-0da4-4f28-b10d-fe323a0f1eba',
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            },
            msalAuth: {
                authority:
                    'https://login.microsoftonline.com/f5e0c226-0da4-4f28-b10d-fe323a0f1eba',
                scopes: ['https://storage.azure.com/user_impersonation']
            },
            msalRequest: {
                scopes: [
                    'user.read',
                    'calendars.read',
                    'calendars.readwrite'
                ]
            },
            myAccounts: null,
            appointments: undefined,
            todayAppointments: undefined,
            tomorrowAppointments: undefined,
            threeDaysAppointments: undefined,
            isAuthenticated: false,
            accessToken: '',
            localStorageKey: 'cb3ad985-7538-4961-be67-3182818a31ce.f5e0c226-0da4-4f28-b10d-fe323a0f1eba-login.windows.net-accesstoken-31022e90-0ead-4a35-a87a-97796ac0bfe8-f5e0c226-0da4-4f28-b10d-fe323a0f1eba-calendars.read openid profile user.read email--',
        };
    },
    getters: {
        clients(state) {
            return state.clients;
        },
        getBool(state) {
            return state.isAuthenticated;
        },
        todayAppointments(state) {
            return state.todayAppointments;
        },
        tomorrowAppointments(state) {
            return state.tomorrowAppointments;
        },
        threeDaysAppointments(state) {
            return state.threeDaysAppointments;
        },
        appointments(state) {
            return state.appointments;
        },
        getToken(state) {
            return state.accessToken;
        },
        loadedStoreAppointments(state) {
            return (appointmentId) => {
                return state.appointments.find((appointment) => appointment.id === appointmentId);
            };
        },
        client(state) {
            return (clientId) => {
                return state.clients.find(x => x.empl.find(y => y.clinr === clientId));
            };
        },
    },
    mutations: {
        addAppointments (state, newAppointment) {
            // mutate state
            state.appointments = newAppointment;
        },
        addTodayAppointments (state, newAppointment) {
            // mutate state
            state.todayAppointments = newAppointment;
        },
        addTomorrowAppointments (state, newAppointment) {
            // mutate state
            state.tomorrowAppointments = newAppointment;
        },
        addThreeDaysAppointments (state, newAppointment) {
            // mutate state
            state.threeDaysAppointments = newAppointment;
        },
        addClients (state, newClients) {
            state.clients = newClients
        },
        getBool(state, newBool){
            state.isAuthenticated = newBool;
        },
        setAccessToken(state, token){
            state.accessToken = token;
        },
        setAccount(state, newAccount){
            state.myAccounts = newAccount;
        },
    }
});

export default store;
