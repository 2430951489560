<template>
  <div class="card-ground">
    <ion-header>
      <ion-icon class="menu-icon" slot="icon-only" :icon="menu"></ion-icon>
    </ion-header>
    <div class="round-background">
    </div>
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ subject }}</ion-card-title>
        <ion-card-subtitle>{{ new Date(start).toLocaleDateString("de-DE") }}</ion-card-subtitle>
        <ion-card-subtitle>{{ new Date(start).toLocaleTimeString("de-DE")}} - {{ new Date(end).toLocaleTimeString("de-DE")}}</ion-card-subtitle>
        <ion-card-subtitle>{{ loc.replace("Microsoft Teams-Besprechung;", " ")}}</ion-card-subtitle>
        <ion-card-subtitle class="link">
          <a v-if="url !== null" :class="openURL" @click="openURL" target="_blank">Open in Teams</a>
        </ion-card-subtitle>
        <ion-card-subtitle class="link">
        </ion-card-subtitle>
      </ion-card-header>
      <hr/>
      <ion-card-content>
        <div class="body-container" v-html="body">
        </div>
        <div class="button-container">
          <base-button class="nav-button" @click="navigation">Navigation</base-button>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {menu} from "ionicons/icons";
import BaseButton from "../../base/BaseButton";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
    IonHeader,
} from "@ionic/vue";

export default {
  name: "AppointmentOverview",
  components: {
    BaseButton,
    IonHeader,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
  },
  data() {
    return {
      menu,
    };
  },
  props: [
    'id',
    'subject',
    'body',
    'start',
    'end',
    'loc',
    'url',
    'outlookWeb',
  ],
  methods:{
    openURL(){
      window.open(
          this.url['joinUrl'],
          "_blank"
      )
    },
    openOutlook(){
      window.open(
          this.outlookWeb,
          "_blank"
      )
    },
    navigation() {
      window.open(
          `https://www.google.com/maps/dir/?api=1&destination=${this.loc}`,
          "_blank"
      );

    }
  },
}

</script>

<style scoped>

ion-card-title{
  margin-bottom: 1rem;
  font-size: 1rem
}
ion-card-header {
  height: 9.5rem;
  max-height: 9.5rem;
  padding-inline: 0.5rem;
}

hr {
  width: 15rem;
  display: inline-block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0.2rem 0;
  padding: 0;
}

.body-container{
  overflow-x: auto;
  height: 11rem;
  max-height: 11rem;
  text-align: left;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.menu-icon {
  font-size: 50px;
  margin-left: 0.5rem;
}

.card-ground {
  background-color: var(--ion-color-primary);
  width: 100%;
  height: 100%;
  position: relative;
}

h1 {
  height: 1.5rem;
  padding-left: 2.5rem;
  align-items: center;
  display: flex;
  color: black;
}

ion-card {
  margin-inline: 0;
  padding-top: 2rem;
  width: 20rem;
  height: 30rem;
  max-height: 30rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ion-card-title {
  font-weight: bolder;
}

ion-card-subtitle {
  margin-top: 0;
}

.round-background {
  height: 30rem;
  width: 30rem;
  background-color: #c06f62;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link {
  color: darkblue;
}

.button-container {
  text-align: center;
  margin-top: 1rem;
}


</style>