<template>
  <ion-page>
    <ion-header>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-6 text-left">
            <ion-buttons>
              <ion-back-button class="go-back" defaultHref="/home" slot="start"></ion-back-button>
            </ion-buttons>
          </div>
          <div class="col-6 text-right">
            <ion-icon @click="openPDF" class="help-icon" slot="icon-only" :icon="help"></ion-icon>
            <router-link to="/home">
              <ion-icon class="home-icon" slot="icon-only" :icon="home"></ion-icon>
            </router-link>
          </div>
        </div>
      </div>
    </ion-header>
    <ion-content>
      <GMapMap
          class="map"
          :center="center"
          :zoom="12"
          map-type-id="roadmap">
        <GMapMarker
            :position="center"
            :icon="currentLocationIcon"
        >
        </GMapMarker>
        <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :icon='{
              url: `${m.image}`,
            }'
            @click="openMarker(m.id)">
          <GMapInfoWindow
              :closeclick="true"
              :options=" {
              maxWidth: 420,
              maxHeight: 320,
          }"
              @closeclick="openMarker(null)"
              :opened="openedMarkerID === m.id">
            <div>
              <strong>{{ m.name }}</strong>
            </div>
            <div>{{ m.bez }}</div>
            <div v-if="m.days === null">
              Kein Besuch
            </div>
            <div v-else>
              Last visit: <strong>{{ m.days }} days</strong>
            </div>
            <div class="link">
              <a class="link" @click="dial(m.tel)"><em>{{ m.tel }}</em></a>
            </div>
            <div class="link">
              <a class="link" @click="sendmail(m.email)"><em>{{ m.email }}</em></a>
            </div>
            <div class="link">
              <router-link
                  :to="{
                        name: 'AppointmentFormPage',
                        params: {
                            compName: m.name,
                            firstname: m.fName,
                            name: m.lName,
                            plz: m.zip,
                            emailSub : m.email,
                            loc: m.add,
                            city: m.city,
                        }}">
                <a @click="loadForm" class="link">Termin vereinbaren</a>
              </router-link>
            </div>
            <div class="link">
              <a class="link" @click="openGoogleMap(m.position['lat'], m.position['lng'])">Routerplaner Google</a>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>
    </ion-content>
  </ion-page>
</template>

<script>
import {help, home, map, menu} from "ionicons/icons";
import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage} from "@ionic/vue";


export default {
  name: 'VisitMap',
  components: {
    IonHeader,
    IonIcon,
    IonPage,
    IonContent,
    IonBackButton,
    IonButtons,
  },
  data() {
    return {
      currentLocationIcon: '../assets/img/user-icon.svg',
      openedMarkerID: null,
      currentLocation: null,
      visitText: null,
      menu,
      map,
      help,
      home,
      center: {
        lat: 47.0494,
        lng: 8.26808,
      },
      markers: [],
      list: []    }
  },
  props: [
    'email',
    'add',
    'city',
    'poscod',
    'fName',
    'lName',
    'name',
  ],
  methods: {
    loadForm() {
      this.$router.push(
          {
            name: 'AppointmentFormPage',
            props: this.email
          }).catch(error => {
        console.log(error)
        this.$router.push("/login");
        alert('Please log in again!')
      })
    },
    // Function for opening help source
    openPDF() {
      window.open("./assets/help.pdf");
    },
    // function for calling the dynamic phone number
    dial: function (number) {
      window.location = 'tel:' + number;
    },

    // function for sending a mail of the dynamic email
    sendmail: function (address) {
      window.location = 'mailto:' + address;
    },
    openMarker(id) {
      this.openedMarkerID = id
    },
    openGoogleMap(lat, lng) {
      window.open(
          `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
          "_blank"
      );
    },
  },
  async created() {
    this.$store.commit('addClients', JSON.parse(localStorage.getItem('clients')));
    // console.log(JSON.parse(localStorage.getItem('clients')))

    try {
      for (let i = 0; i < this.$store.getters.clients.length; i++) {
        let fName = ' ';
        let lName = ' ';
        let zip = this.$store.getters.clients[i].poscod;
        let days = this.$store.getters.clients[i].days;
        let name = this.$store.getters.clients[i].bpcnam;
        let tel = this.$store.getters.clients[i].custel;
        let bez = this.$store.getters.clients[i].stat;
        let add = this.$store.getters.clients[i].bpaadd;
        let city = this.$store.getters.clients[i].cty;
        let id = parseInt(this.$store.getters.clients[i].bpcnr);

        // putting lat and lng together for comparing easier
        let latLng = "lat:" + parseFloat(this.$store.getters.clients[i].lat) + "," + "lng:" + parseFloat(this.$store.getters.clients[i].lng);

        let email = "";
        if (this.$store.getters.clients[i].cusmail !== null) {
          email = this.$store.getters.clients[i].cusmail;
        } else {
          email = "not available";
        }

        // This section of the code is for the custom markers
        let image = "";
        if (this.$store.getters.clients[i].stat === "Keine") {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Installateur" && this.$store.getters.clients[i].days === null) {
          image = '../assets/img/installateur_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Architekt/Planer" && this.$store.getters.clients[i].days === null) {
          image = '../assets/img/black_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Endkunde" && this.$store.getters.clients[i].days === null) {
          image = '../assets/img/blue_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Dienstleister" && this.$store.getters.clients[i].days === null) {
          image = '../assets/img/blue_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Beratung" && this.$store.getters.clients[i].days === null) {
          image = '../assets/img/blue_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Lieferant" && this.$store.getters.clients[i].days === null) {
          image = '../assets/img/blue_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Immobilien TU/GU/Bau" && this.$store.getters.clients[i].days === null) {
          image = '../assets/img/immo_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Endkunde" && this.$store.getters.clients[i].days <= 90) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Endkunde" && this.$store.getters.clients[i].days >= 91 && this.$store.getters.clients[i].days <= 120) {
          image = '../assets/img/blue_orange.svg';
        } else if (this.$store.getters.clients[i].stat === "Endkunde" && this.$store.getters.clients[i].days >= 120) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Dienstleister" && this.$store.getters.clients[i].days <= 90) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Dienstleister" && this.$store.getters.clients[i].days >= 91 && this.$store.getters.clients[i].days <= 120) {
          image = '../assets/img/blue_orange.svg';
        } else if (this.$store.getters.clients[i].stat === "Dienstleister" && this.$store.getters.clients[i].days >= 120) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Beratung" && this.$store.getters.clients[i].days <= 90) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Beratung" && this.$store.getters.clients[i].days >= 91 && this.$store.getters.clients[i].days <= 120) {
          image = '../assets/img/blue_orange.svg';
        } else if (this.$store.getters.clients[i].stat === "Beratung" && this.$store.getters.clients[i].days >= 120) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Lieferant" && this.$store.getters.clients[i].days <= 90) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Lieferant" && this.$store.getters.clients[i].days >= 91 && this.$store.getters.clients[i].days <= 120) {
          image = '../assets/img/blue_orange.svg';
        } else if (this.$store.getters.clients[i].stat === "Lieferant" && this.$store.getters.clients[i].days >= 120) {
          image = '../assets/img/blue_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Immobilien TU/GU/Bau" && this.$store.getters.clients[i].days <= 90) {
          image = '../assets/img/lightgrey_green-svg.svg';
        } else if (this.$store.getters.clients[i].stat === "Immobilien TU/GU/Bau" && this.$store.getters.clients[i].days >= 91 && this.$store.getters.clients[i].days <= 120) {
          image = '../assets/img/lightgrey_orange-svg.svg';
        } else if (this.$store.getters.clients[i].stat === "Immobilien TU/GU/Bau" && this.$store.getters.clients[i].days > 120) {
          image = '../assets/img/lightgrey_red-svg.svg';
        } else if (this.$store.getters.clients[i].stat === "Installateur" && this.$store.getters.clients[i].days <= 90) {
          image = '../assets/img/installateur_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Installateur" && this.$store.getters.clients[i].days >= 91 && this.$store.getters.clients[i].days <= 120) {
          image = '../assets/img/installateur_orange.svg';
        } else if (this.$store.getters.clients[i].stat === "Installateur" && this.$store.getters.clients[i].days > 120) {
          image = '../assets/img/installateur_red.svg';
        } else if (this.$store.getters.clients[i].stat === "Architekt/Planer" && this.$store.getters.clients[i].days <= 90) {
          image = '../assets/img/black_green.svg';
        } else if (this.$store.getters.clients[i].stat === "Architekt/Planer" && this.$store.getters.clients[i].days >= 91 && this.$store.getters.clients[i].days <= 120) {
          image = '../assets/img/black_orange.svg';
        } else if (this.$store.getters.clients[i].stat === "Architekt/Planer" && this.$store.getters.clients[i].days > 120) {
          image = '../assets/img/black_red.svg';
        } else {
          image = '../assets/img/blue_green.svg';
        }

        // put all values in this array(object)
        this.list.push({
          fName: fName,
          lName: lName,
          zip: zip,
          email: email,
          days: days,
          bez: bez,
          add: add,
          city: city,
          tel: tel,
          image: image,
          name: name,
          id: id,
          latLng: latLng,
        })
      }



      // remove all uniques from above
      const duplicates = this.list
          .filter((obj, index, array) =>
              array.findIndex(o =>
                  o.latLng === obj.latLng
              ) != index
          );




      // remove all duplicates
      const unique = [];
      this.list.map(x => unique.filter(a => a.latLng == x.latLng ).length > 0 ? null : unique.push(x));

      // splitting the latLng
      for (let i = 0; i < unique.length; i++) {
        let obj = unique[i].latLng.split(',').reduce((result, token)=>{
          let [key, value] = token.split(':');

          result[key] = value;
          return result;
        }, {});

        let position = {
          lat: parseFloat(obj.lat),
          lng: parseFloat(obj.lng)
        }

        this.markers.push({
          fName: unique[i].fName,
          lName: unique[i].lName,
          zip: unique[i].zip,
          email: unique[i].email,
          days: unique[i].days,
          bez: unique[i].bez,
          add: unique[i].add,
          city: unique[i].city,
          tel: unique[i].tel,
          image: unique[i].image,
          name: unique[i].name,
          id: unique[i].id,
          position: position
        })

      }



      // The duplicates have the same coordinates. So these have to be changed
      for (let i = 0; i < duplicates.length; i++) {
        let obj = duplicates[i].latLng.split(',').reduce((result, token)=>{
          let [key, value] = token.split(':');

          result[key] = value;
          return result;
        }, {});

        // Add 0.0001 to all lat and lng
        let position = {
          lat: parseFloat(obj.lat) + parseFloat('0.0001'),
          lng: parseFloat(obj.lng) + parseFloat('0.0001')
        }

        // console.log(position)

        this.markers.push({
          fName: duplicates[i].fName,
          lName: duplicates[i].lName,
          zip: duplicates[i].zip,
          email: duplicates[i].email,
          days: duplicates[i].days,
          bez: duplicates[i].bez,
          add: duplicates[i].add,
          city: duplicates[i].city,
          tel: duplicates[i].tel,
          image: duplicates[i].image,
          name: duplicates[i].name,
          id: duplicates[i].id,
          position: position
        })
      }
    } catch (e) {
      console.log("Error:" + e);
    }

    // Get the current location (coordinates) and put this on the center
    this.$getLocation()
        .then((coordinates) => {
          this.center.lat = coordinates.lat;
          this.center.lng = coordinates.lng;
          console.log(this.center);
        })
        .catch((error) => {
          console.log(error);
        });

  }
}
</script>

<style scoped>

ion-toolbar {
  background-color: #ffffff;
}

.link {
  color: darkblue;
}

.go-back {
  color: var(--ion-color-primary);
}

.home-icon {
  font-size: 35px;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  color: var(--ion-color-primary);
}

.help-icon {
  font-size: 35px;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.help-icon:hover {
  cursor: pointer;
}

.map {
  width: 100%;
  height: 100%;
}

</style>