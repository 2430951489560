<template>
  <ion-item :router-link="`/appointment/${appointment.id}`">
    <strong>{{ appointment.subject.length >= 14 ? appointment.subject.substring(0,16) + "..."  : appointment.subject }}</strong>, {{ new Date(appointment.start.dateTime).toLocaleString("de-DE") }}
  </ion-item>
</template>

<script>

import {
  IonItem
} from "@ionic/vue";

export default {
  name: "AppointmentListItem",
  props: [
      "appointment"
  ],
  components: {
    IonItem
  },
}
</script>

<style scoped>

ion-item {
  margin-right: 0.5rem;
}

</style>