import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import store from './store/index.js';
import Emitter from 'tiny-emitter';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Vue3Geolocation from 'vue3-geolocation';
import VSwitch from 'v-switch-case';

import {IonicVue} from '@ionic/vue';
import VueGoogleMaps from '@fawmi/vue-google-maps'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';


/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store)
    .use(Vue3Geolocation)
    .use(VSwitch)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAmj_UyK-MXNbeePjZBQg8pewsAt6HSdak',
        }
    })
;


app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
app.component('date-picker', Datepicker);

router.isReady().then(() => {
    app.mount('#app');
});
